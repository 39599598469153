import React from 'react'
import Navbar from './Navbar.js'

export default ({image, alignImage, children, height}) => {
  let style = {}
  if (height) {
    style.minHeight = `calc(${height} - 60px)`
  }
  let outerStyle = {backgroundImage: `url('${image}')`}
  if (alignImage) {
    outerStyle.backgroundPosition = alignImage
  }
  return (
    <div className="splash-header text-white" style={outerStyle}>
      <Navbar/>
      <div className="splash-header__body container-fluid" style={style}>
        {children}
      </div>
    </div>
  )
}
