import React from 'react'
import logo from './logo.svg'
import logo_light from './logo-light-color.svg'
import {Link} from 'gatsby'
import classnames from 'classnames'

export default function Navbar({mode}) {
  mode = mode || 'dark'
  return (
    <nav className={classnames(`navbar navbar-expand-lg navbar-${mode}`, mode=='light' && `bg-${mode}` )}>
      <Link className="navbar-brand" to="/">
        <img className="logo-icon mr-3" src={mode==='dark' ? logo_light : logo}/>
        Floating Island
      </Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/about-us">Who we are</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/projects">What we do</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/hire">Hire us</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
