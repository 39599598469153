import React from 'react'
import Layout from '../Layout.js'
import Navbar from '../Navbar.js'
import SplashHeader from '../SplashHeader.js'
import {Link} from 'gatsby'

export default () => {
  return (
    <Layout>
      <SplashHeader image="https://images.unsplash.com/photo-1524397057410-1e775ed476f3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80">
        <div className="splash-text">
          <h1 className="mb-4">
            <strong>Headache Free</strong><br/>
            Development Agency
          </h1>
          <p className="mb-5">
            Work with a dependable team that won't waste your time and won't
            make a mess
          </p>
          <Link className="btn btn-lg btn-callout" to="/hire">Start Now</Link>
        </div>
      </SplashHeader>
    </Layout>
  )
}
