import React from 'react'
import Head from './Head.js'

export default function({children, page}) {
  let id
  if (page) {
    id = `page-${page}`
  }
  return (
    <div id={id}>
      <Head/>
      {children}
    </div>
  )
}
