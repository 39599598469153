import React from 'react'
import {Helmet} from 'react-helmet'

export default function() {
  return (
    <Helmet>
      <title>Floating Island Software</title>
      <link href="https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet"/>
    </Helmet>
  )
}
